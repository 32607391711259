import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Card,
  CardContent,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import PersonalityAssessment from "./PersonalityAssessment";
import PersonalityAssessmentGraph from "./PersonalityAssessmentGraph";
import clsx from "clsx";
import ButtonField from "../../reusableComponents/ButtonField";
import { USER_TOKEN } from "../../redux/actions/types";
import { postUserAccessLog } from "../../redux/actions/authActions";
import {
  getAssessmentResult,
  getPsychometricQuestions,
  postPsychometricQuestions,
  getPsychometricResultAnswers,
  sendCounsellingRequest,
  getCounsellingRequest,
} from "../../redux/actions/dashboardActions";
import isEmpty from "../../reusableComponents/is-empty";
import Counselling from "./Counselling";
import { getDateTime } from "../../reusableComponents/GetDate";
import MediaRecorder from "./MediaRecorder";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    width: "100%",
    content: {
      margin: "0",
    },
  },
  accordionSummary: {
    width: "100%",
    // padding: "0",
    // paddingTop:'0',
    margin: "0",
    content: {
      padding: "0",
      paddingTop: "0",
      margin: "0",
    },
  },
  gridItem: {
    // width: '100%',
    padding: "0",
    margin: "0",
    content: {
      margin: "0",
    },
  },
  heading: {
    paddingLeft: "10px",
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  headingBold: {
    // fontWeight: 600
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  newBusinessContainer: {
    textAlign: "center",
  },
  againNewBusinessTest: {
    textAlign: "right",
  },
  table: {
    minWidth: 650,
  },
  tableHeading: {
    fontWeight: 600,
  },
  tableBordered: {
    borderWidth: 1,
    borderColor: "#ddd",
    borderStyle: "solid",
  },
  cardRoot: {
    minWidth: 275,
  },
  title: {
    display: "flex",
    color: "#2B2B2B",
    margin: theme.spacing(1, 0),
  },
  titleBold: {
    fontWeight: 600,
    paddingRight: 4,
  },
  centerTitle: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
}));
// const AccordionSummary = withStyles({
//     content: {
//       margin: "0",
//       "&$expanded": {
//         margin: "0",
//         padding: 0,
//         backgroundColor: "#008dd2",
//       },
//     },
//     expanded: {},
//   })(MuiAccordionSummary);

const Dashboard = ({
  history,
  auth,
  dashboard,
  getAssessmentResult,
  getPsychometricQuestions,
  postPsychometricQuestions,
  getPsychometricResultAnswers,
  sendCounsellingRequest,
  getCounsellingRequest,
  postUserAccessLog,
}) => {
  const classes = useStyles();
  const { isAuthenticated, user } = auth;
  const {
    assessmentResult,
    psychometricQuestions,
    psychometricResult,
    comunicationVideoURL,
    aiResponse,
    counsellingRequest,
  } = dashboard;

  const [expanded, setExpanded] = useState(false);
  const [personaliyTest, setPersonalityTest] = useState(false);
  const [anotherCounselling, setAnotherCounselling] = useState(false);

  var tabVisibility = document.visibilityState;

  const [timeInterval, setTimeInterval] = useState(0);

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 60000);

  useEffect(() => {
    if (isAuthenticated && tabVisibility === "visible") {
      const data = {
        pageName: "MVK",
        recordedDate: new Date(),
      };
      postUserAccessLog(data);
    }
    // console.log("Individual Course Useage , Date", new Date(),"UserId :", user._id,"Location : MVVK");
  }, [timeInterval, tabVisibility]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (isEmpty(assessmentResult) && isAuthenticated) {
      getAssessmentResult(user.email);
    }
    if (isEmpty(psychometricQuestions) && personaliyTest && isAuthenticated) {
      getPsychometricQuestions();
    }
    if (isEmpty(psychometricResult) && isAuthenticated) {
      getPsychometricResultAnswers(user._id);
    }
    if (isEmpty(counsellingRequest) && isAuthenticated) {
      getCounsellingRequest();
    }
  }, [isAuthenticated, personaliyTest, psychometricResult]);

  console.log(psychometricResult);
  console.log(psychometricResult?.traitOne?.suitJob?.length);
  const firstOne =
    psychometricResult?.traitOne?.suitJob?.length > 0
      ? psychometricResult?.traitOne
      : null;
  const secondOne =
    psychometricResult?.traitTwo?.suitJob?.length > 0
      ? psychometricResult?.traitTwo
      : null;
  const thirdOne =
    psychometricResult?.traitThree?.suitJob?.length > 0
      ? psychometricResult?.traitThree
      : null;
  const fourthOne =
    psychometricResult?.traitFour?.suitJob?.length > 0
      ? psychometricResult?.traitFour
      : null;

  console.log(firstOne);
  const psychometricGraphData = [
    {
      trait_name: "Introvert",
      scores: isEmpty(firstOne?.Introvert) ? 0 : firstOne?.Introvert,
    },
    {
      trait_name: "Extrovert",
      scores: isEmpty(firstOne?.Extrovert) ? 0 : firstOne?.Extrovert,
    },
    {
      trait_name: "Thinker",
      scores: isEmpty(secondOne?.Thinker) ? 0 : secondOne?.Thinker,
    },
    {
      trait_name: "Judgemental",
      scores: isEmpty(secondOne?.Judgemental) ? 0 : secondOne?.Judgemental,
    },
    {
      trait_name: "AttentionToDetails",
      scores: isEmpty(thirdOne?.AttentionToDetails)
        ? 0
        : thirdOne?.AttentionToDetails,
    },
    {
      trait_name: "HighFlying",
      scores: isEmpty(thirdOne?.HighFlying) ? 0 : thirdOne?.HighFlying,
    },
    {
      trait_name: "Composed",
      scores: isEmpty(fourthOne?.Composed) ? 0 : fourthOne?.Composed,
    },
    {
      trait_name: "Restless",
      scores: isEmpty(fourthOne?.Restless) ? 0 : fourthOne?.Restless,
    },
  ];

  return (
    <Container maxWidth="lg" className="dashboardContainer">
      <Grid container spacing={0}>
        {/* <Grid item xs={12}>
          <div className="mainViewHeading">
            <Typography className="headingText" variant="h5">
              Minimum Viable Knowledge (MVK)
            </Typography>
          </div>
        </Grid> */}
        <Grid item xs={12}>
          {/* <Accordion data={CheckKnowledgeList} /> */}
          <div className={classes.accordionRoot}>
            <MuiAccordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <MuiAccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                {/* <Typography className={expanded === 'panel2' ? clsx(classes.heading, classes.headingBold) :classes.heading}>Check your personality</Typography> */}
                {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
                <Grid container>
                  {isEmpty(psychometricResult) ? (
                    <Grid item xs={2} md={2} className="colorSpace1">
                      <div className="iconCard">
                        <ClearIcon className="Icon" />
                      </div>
                      {/* <ClearIcon className='Icon'/> */}
                    </Grid>
                  ) : (
                    <Grid item xs={2} md={2} className="colorSpace2">
                      <div className="iconCard">
                        <DoneIcon className="Icon" />
                      </div>
                      {/* <DoneIcon className='Icon'/> */}
                    </Grid>
                  )}
                  <Grid item xs={7} md={7}>
                    <Typography
                      className={
                        expanded === "panel2"
                          ? clsx(classes.heading, classes.headingBold)
                          : classes.heading
                      }
                    >
                      Check your personality
                    </Typography>
                  </Grid>

                  <Grid item xs={3} md={3}>
                    <Typography
                      className={
                        expanded === "panel2"
                          ? clsx(classes.heading, classes.headingBold)
                          : classes.heading
                      }
                    >
                      {isEmpty(psychometricResult) ? "Incomplete" : "Complete"}
                    </Typography>
                  </Grid>
                </Grid>
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                <Grid container>
                  <Grid item xs={12}>
                    {!personaliyTest && isEmpty(psychometricResult) && (
                      <div className={classes.newBusinessContainer}>
                        <ButtonField
                          name="Check your personality Assessment"
                          onClick={() => setPersonalityTest(true)}
                          color="#003399"
                        />
                      </div>
                    )}
                    {personaliyTest && isEmpty(psychometricResult) && (
                      <PersonalityAssessment
                        userId={user._id}
                        psychometricQuestions={psychometricQuestions}
                        postPsychometricQuestions={postPsychometricQuestions}
                      />
                    )}
                  </Grid>
                  {!isEmpty(psychometricResult) && (
                    <Grid item xs={12}>
                      {/* <TableContainer component={Paper}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className={classes.tableBordered}>Personality Traits</TableCell>
                                                        <TableCell className={classes.tableBordered} colSpan={4} align="center">Scores</TableCell>
                                                        <TableCell className={classes.tableBordered}>Suitable Roles</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className={classes.tableBordered}>Extrovert or Introvert</TableCell>
                                                        <TableCell className={classes.tableBordered}>Introvert</TableCell>
                                                        <TableCell className={classes.tableBordered}>{isEmpty(firstOne?.Introvert) ? 0 : firstOne?.Introvert}</TableCell>
                                                        <TableCell className={classes.tableBordered}>Extrovert</TableCell>
                                                        <TableCell className={classes.tableBordered}>{isEmpty(firstOne?.Extrovert) ? 0 : firstOne?.Extrovert}</TableCell>
                                                        <TableCell className={classes.tableBordered}>{psychometricResult?.traitOne?.suitJob[0]?.matchingJobs?.join()}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={classes.tableBordered}>Thinker or Judgemental</TableCell>
                                                        <TableCell className={classes.tableBordered}>Thinker</TableCell>
                                                        <TableCell className={classes.tableBordered}>{isEmpty(secondOne?.Thinker) ? 0 : secondOne?.Thinker}</TableCell>
                                                        <TableCell className={classes.tableBordered}>Judgement</TableCell>
                                                        <TableCell className={classes.tableBordered}>{isEmpty(secondOne?.Judgemental) ? 0 : secondOne?.Judgemental}</TableCell>
                                                        <TableCell className={classes.tableBordered}>{psychometricResult?.traitTwo?.suitJob[0]?.matchingJobs?.join()}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={classes.tableBordered}>Attention to detail or High Flying</TableCell>
                                                        <TableCell className={classes.tableBordered}>Attention to Details</TableCell>
                                                        <TableCell className={classes.tableBordered}>{isEmpty(thirdOne?.AttentionToDetails) ? 0 : thirdOne?.AttentionToDetails}</TableCell>
                                                        <TableCell className={classes.tableBordered}>High Flying</TableCell>
                                                        <TableCell className={classes.tableBordered}>{isEmpty(thirdOne?.HighFlying) ? 0 : thirdOne?.HighFlying}</TableCell>
                                                        <TableCell className={classes.tableBordered}>{psychometricResult?.traitThree?.suitJob[0]?.matchingJobs?.join()}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={classes.tableBordered}>Composed or Restless</TableCell>
                                                        <TableCell className={classes.tableBordered}>Composed</TableCell>
                                                        <TableCell className={classes.tableBordered}>{isEmpty(fourthOne?.Composed) ? 0 : fourthOne?.Composed}</TableCell>
                                                        <TableCell className={classes.tableBordered}>Restless</TableCell>
                                                        <TableCell className={classes.tableBordered}>{isEmpty(fourthOne?.Restless) ? 0 : fourthOne?.Restless}</TableCell>
                                                        <TableCell className={classes.tableBordered}>{psychometricResult?.traitFour?.suitJob[0]?.matchingJobs?.join()}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer> */}
                      <PersonalityAssessmentGraph
                        psychometricGraphData={psychometricGraphData}
                      />
                    </Grid>
                  )}
                </Grid>
              </MuiAccordionDetails>
            </MuiAccordion>
            <MuiAccordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <MuiAccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
                // disabled = {isEmpty(psychometricResult)}
              >
                {/* <Typography className={expanded === 'panel3' ? clsx(classes.heading, classes.headingBold) :classes.heading}>Check Your Communication</Typography> */}
                {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}

                <Grid container>
                  {isEmpty(comunicationVideoURL) ? (
                    <Grid item xs={2} md={2} className="colorSpace1">
                      <div className="iconCard">
                        <ClearIcon className="Icon" />
                      </div>
                      {/* <ClearIcon className='Icon'/> */}
                    </Grid>
                  ) : (
                    <Grid item xs={2} md={2} className="colorSpace2">
                      <div className="iconCard">
                        <DoneIcon className="Icon" />
                      </div>
                      {/* <DoneIcon className='Icon'/> */}
                    </Grid>
                  )}
                  <Grid item xs={7} md={7}>
                    <Typography
                      className={
                        expanded === "panel3"
                          ? clsx(classes.heading, classes.headingBold)
                          : classes.heading
                      }
                    >
                      Introduce Yourself
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <Typography
                      className={
                        expanded === "panel3"
                          ? clsx(classes.heading, classes.headingBold)
                          : classes.heading
                      }
                    >
                      {isEmpty(comunicationVideoURL)
                        ? "Incomplete"
                        : "Complete"}
                    </Typography>
                  </Grid>
                </Grid>
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                <Container maxWidth="lg">
                  <Grid container spacing={4}>
                    {isEmpty(comunicationVideoURL) ? (
                      <>
                        <Grid
                          className="videoViewContainer"
                          item
                          xs={12}
                          sm={6}
                          md={6}
                        >
                          <div className="videoView">
                            <MediaRecorder />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <div className="videoContent">
                            <Typography className={classes.heading}>
                              Here are some points to talk about yourself :-
                            </Typography>
                            <ol>
                              <li>Introduce yourself.</li>
                              <li>Share details about your education.</li>
                              <li>
                                Describe your profession or the skills you
                                possess.
                              </li>
                              <li>
                                Discuss what you want to achieve in the next two
                                years.
                              </li>
                              <li>
                                Click "Submit" only when you are satisfied with
                                the recorded video.
                              </li>
                            </ol>
                          </div>
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12}>
                        <Typography
                          className={clsx(
                            classes.heading,
                            classes.titleBold,
                            classes.centerTitle
                          )}
                        >
                          Your record has been successfully saved
                          <br />
                          {aiResponse}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Container>
              </MuiAccordionDetails>
            </MuiAccordion>
            <MuiAccordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <MuiAccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {/* <Typography className={expanded === 'panel1' ? clsx(classes.heading, classes.headingBold) : classes.heading}>Business 101 Assessment</Typography> */}
                {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
                <Grid container>
                  {isEmpty(assessmentResult) ? (
                    <Grid item xs={2} md={2} className="colorSpace1">
                      <div className="iconCard">
                        <ClearIcon className="Icon" />
                      </div>
                      {/* <ClearIcon className='Icon'/> */}
                    </Grid>
                  ) : (
                    <Grid item xs={2} md={2} className="colorSpace2">
                      <div className="iconCard">
                        <DoneIcon className="Icon" />
                      </div>
                      {/* <DoneIcon className='Icon'/> */}
                    </Grid>
                  )}
                  <Grid item xs={7} md={7}>
                    <Typography
                      className={
                        expanded === "panel1"
                          ? clsx(classes.heading, classes.headingBold)
                          : classes.heading
                      }
                    >
                      Business 101 Assessment
                    </Typography>
                  </Grid>

                  <Grid item xs={3} md={3}>
                    <Typography
                      className={
                        expanded === "panel1"
                          ? clsx(classes.heading, classes.headingBold)
                          : classes.heading
                      }
                    >
                      Assessment Taken : {assessmentResult?.length}
                    </Typography>
                  </Grid>
                </Grid>
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                <Grid container>
                  {isEmpty(assessmentResult) ? (
                    <Grid item xs={12}>
                      <div className={classes.newBusinessContainer}>
                        <ButtonField
                          color="#003399"
                          name="Assessment test"
                          onClick={() =>
                            (window.location.href = `${
                              process.env.REACT_APP_CAPABILITY_URL
                            }/menu/ibridge/${USER_TOKEN()}/test/39/Business 101/Business 101/dashboard`)
                          }
                        />
                      </div>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <div className={classes.againNewBusinessTest}>
                        {assessmentResult?.length < 2 ? (
                          <ButtonField
                            color="#003399"
                            name="Assessment test"
                            onClick={() =>
                              (window.location.href = `${
                                process.env.REACT_APP_CAPABILITY_URL
                              }/menu/ibridge/${USER_TOKEN()}/test/39/Business 101/Business 101/dashboard`)
                            }
                          />
                        ) : null}
                      </div>
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                className={classes.tableHeading}
                                align="left"
                              >
                                Skill Level
                              </TableCell>
                              <TableCell
                                className={classes.tableHeading}
                                align="center"
                              >
                                Topic
                              </TableCell>
                              <TableCell
                                className={classes.tableHeading}
                                align="center"
                              >
                                Date
                              </TableCell>
                              <TableCell
                                className={classes.tableHeading}
                                align="right"
                              >
                                Result
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {assessmentResult.map((list) => (
                              <TableRow key={list.id}>
                                <TableCell align="left">
                                  {list.skill_level}
                                </TableCell>
                                <TableCell align="center">
                                  {list.topicname}
                                </TableCell>
                                <TableCell align="center">
                                  {list.insertedDate}
                                </TableCell>
                                <TableCell align="right">
                                  {list.tresult}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}
                </Grid>
              </MuiAccordionDetails>
            </MuiAccordion>
            <MuiAccordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <MuiAccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                {/* <Typography className={expanded === 'panel4' ? clsx(classes.heading, classes.headingBold) :classes.heading}>Book For Counselling</Typography> */}
                {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
                <Grid container>
                  {isEmpty(counsellingRequest) ? (
                    <Grid item xs={2} md={2} className="colorSpace1">
                      <div className="iconCard">
                        <ClearIcon className="Icon" />
                      </div>
                      {/* <ClearIcon className='Icon'/> */}
                    </Grid>
                  ) : (
                    <Grid item xs={2} md={2} className="colorSpace2">
                      <div className="iconCard">
                        <DoneIcon className="Icon" />
                      </div>
                      {/* <DoneIcon className='Icon'/> */}
                    </Grid>
                  )}
                  <Grid item xs={7} md={7}>
                    <Typography
                      className={
                        expanded === "panel4"
                          ? clsx(classes.heading, classes.headingBold)
                          : classes.heading
                      }
                    >
                      Book For Counselling
                    </Typography>
                  </Grid>

                  <Grid item xs={3} md={3}>
                    <Typography
                      className={
                        expanded === "panel4"
                          ? clsx(classes.heading, classes.headingBold)
                          : classes.heading
                      }
                    >
                      Scheduled Counselling : {counsellingRequest?.length}
                    </Typography>
                  </Grid>
                </Grid>
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                <Container maxWidth="lg">
                  {!isEmpty(counsellingRequest) && (
                    <Grid container spacing={4}>
                      {counsellingRequest?.map((list) => (
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <Card className={classes.cardRoot}>
                            <CardContent>
                              <div className={classes.title}>
                                <Typography className={classes.titleBold}>
                                  Education :
                                </Typography>
                                <Typography>{list?.education}</Typography>
                              </div>
                              <div className={classes.title}>
                                <Typography className={classes.titleBold}>
                                  City :
                                </Typography>
                                <Typography>{list?.city}</Typography>
                              </div>
                              <div className={classes.title}>
                                <Typography className={classes.titleBold}>
                                  Scheduled Date & Time :
                                </Typography>
                                <Typography>
                                  {getDateTime(list?.scheduledDateTime)}
                                </Typography>
                              </div>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <ButtonField
                          color="#003399"
                          name="Book another counselling"
                          onClick={() => setAnotherCounselling(true)}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {(!anotherCounselling && isEmpty(counsellingRequest)) ||
                  (anotherCounselling && !isEmpty(counsellingRequest)) ? (
                    <Counselling
                      user={user}
                      sendCounsellingRequest={sendCounsellingRequest}
                    />
                  ) : null}
                </Container>
              </MuiAccordionDetails>
            </MuiAccordion>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  dashboard: PropTypes.object.isRequired,
  getAssessmentResult: PropTypes.func.isRequired,
  getPsychometricQuestions: PropTypes.func.isRequired,
  postPsychometricQuestions: PropTypes.func.isRequired,
  getPsychometricResultAnswers: PropTypes.func.isRequired,
  sendCounsellingRequest: PropTypes.func.isRequired,
  getCounsellingRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  dashboard: state.dashboard,
});

const mapDispatchToProps = {
  getAssessmentResult,
  getPsychometricQuestions,
  postPsychometricQuestions,
  getPsychometricResultAnswers,
  sendCounsellingRequest,
  getCounsellingRequest,
  postUserAccessLog,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
